import { Component, Input } from '@angular/core';
import { EmployeeVoucherModel } from '@modules/benefit/models';

@Component({
    selector: 'app-history-element',
    templateUrl: './history-element.component.html',
    styleUrls: ['./history-element.component.scss']
})
export class HistoryElementComponent {
    @Input() voucher: EmployeeVoucherModel = new EmployeeVoucherModel();
}
