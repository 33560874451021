<nav data-cy="side-nav" [class.collapsed]="!sidebarOpen" role="navigation" class="dashboard-menu" [class.theme-dark]="theme === 'dark'">
    <section class="side-nav-brand p-overflow-hidden">
        <ng-container [ngTemplateOutlet]="brandLogo"></ng-container>
    </section>
    <!--[ngClass]="[item.disabled ? 'item-disabled' : '', (item.isPreview && item.disabled) && 'no-hover']"-->

    <section class="side-nav-top">
        <section class="top-nav">
            <ul role="menu" id="nav-list-top">
                <li
                    [attr.data-cy]="item.id"
                    [pTooltip]="item.isPreview ? item.tooltip : ''"
                    tooltipStyleClass="epx-tooltip"
                    role="menuitem"
                    *ngFor="let item of topMenu; let i = index"
                    [ngClass]="{ 'item-disabled': item.disabled, 'no-hover': item.disabled && item.isPreview }"
                    routerLinkActive="active"
                    [routerLink]="item.routerLink"
                    [id]="item.id">
                    <a class="menu-item-content" [id]="item.id">
                        <div class="menu-icon">
                            <epx-svg-icon [size]="24" [icon]="item.svgIcon || 'sparkle'"></epx-svg-icon>
                        </div>
                        <div class="menu-label">
                            <span [translate]="item.label || ''"></span>
                        </div>
                    </a>
                </li>
            </ul>
        </section>
    </section>
    <section class="side-nav-bottom">
        <app-epx-lang-select [sidebarOpen]="sidebarOpen"></app-epx-lang-select>
        <section class="bottom-nav">
            <ul>
                <li class="flex align-items-center justify-content-end no-hover relative" id="toggle" [style.padding]="'0 22px'">
                    <app-app-version *ngIf="sidebarOpen" class="absolute" [style.left.px]="10"></app-app-version>
                    <p-button (click)="sidebarToggleEvent.emit($event)" styleClass="epx-button epx-button-text">
                        <ng-template pTemplate="icon">
                            <epx-svg-icon [size]="28" color="Subtile" [icon]="sidebarOpen ? 'chevron_left' : 'chevron_right'"></epx-svg-icon>
                        </ng-template>
                    </p-button>
                </li>
            </ul>
        </section>
    </section>
</nav>

<ng-template #brandLogo>
    <section class="brand-logo-wrapper">
        <svg-icon [applyClass]="true" class="brand-logo-svg" src="assets/images/brand/icons/brand-logo.svg"></svg-icon>
    </section>
</ng-template>
