import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-epx-circle-progress',
    templateUrl: './epx-circle-progress.component.html',
    styleUrl: './epx-circle-progress.component.scss'
})
export class EpxCircleProgressComponent {
    @Input() currentValue = 0;
    @Input() maxValue = 100;
}
