import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'designationTypeName'
})
export class DesignationNamesPipe implements PipeTransform {
    constructor(private translate: TranslateService) {}

    transform(label: string): string {
        const value = `MODULE_PAYROLL.DESIGNATIONS.TYPES.${label.toUpperCase()}`;
        return this.translate.instant(value);
    }
}
