import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FileUpload, FileUploadHandlerEvent } from 'primeng/fileupload';
import { FileUploadBaseConfig } from '@core/config/file-upload-config';
import { IFileUploadConfig } from '@shared/interfaces/files.interface';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {
    @ViewChild('fileDropRef') fileInput!: FileUpload;
    @Input() config: IFileUploadConfig = FileUploadBaseConfig;
    @Input() helpText = 'COMPONENTS.FILE_UPLOAD.HELP_TEXT_DEFAULT';
    @Input() isUploading = false;
    @Output() dialogVisibleEmitter = new EventEmitter<boolean>();
    @Output() uploadEmitter = new EventEmitter<File[]>();

    onUpload($event: FileUploadHandlerEvent) {
        this.uploadEmitter.emit($event.files);
        this.fileInput.clear();
    }
}
