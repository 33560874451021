enum ValidationMessagesMandatory {
    EMAIL = 'VALIDATION.MANDATORY.EMAIL',
    PASSWORD = 'VALIDATION.MANDATORY.PASSWORD',
    STAFF_NUMBER = 'VALIDATION.MANDATORY.STAFF_NUMBER',
    GENERIC_REQUIRED = 'VALIDATION.REQUIRED'
}

enum ValidationMessagesPatterns {
    EMAIL = 'VALIDATION.PATTERNS.EMAIL',
    PHONE = 'VALIDATION.PATTERNS.PHONE',
    PASSWORD = 'VALIDATION.PATTERNS.PASSWORD_WEAK',
    WEB = 'VALIDATION.PATTERNS.WEB'
}

enum CustomerModuleValidations {
    'CUSTOMER_NAME_MANDATORY' = 'MODULE_CUSTOMER.VALIDATION.CREATE_CUSTOMER.CUSTOMER_NAME',
    'CUSTOMER_BUDGET_MANDATORY' = 'MODULE_CUSTOMER.VALIDATION.CREATE_CUSTOMER.BUDGET'
}

enum BenefitModuleValidation {
    'BENEFIT_NAME_MANDATORY' = 'MODULE_BENEFIT.VALIDATION.CREATE_BENEFIT.BENEFIT_NAME',
    'BENEFIT_CATEGORY_MANDATORY' = 'MODULE_BENEFIT.VALIDATION.CREATE_BENEFIT.BENEFIT_CATEGORY',
    'BENEFIT_INTERNAL_CATEGORY_MANDATORY' = 'MODULE_BENEFIT.VALIDATION.CREATE_BENEFIT.BENEFIT_INTERNAL_CATEGORY'
}

export { ValidationMessagesMandatory, ValidationMessagesPatterns, CustomerModuleValidations, BenefitModuleValidation };
