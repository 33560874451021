import { IFileUploadConfig } from '@shared/interfaces/files.interface';

const FileUploadBaseConfig: IFileUploadConfig = {
    maxFileSize: 50000000,
    fileLimit: 5,
    acceptedFiles: 'application/*, .txt',
    autoUpload: true
};

export { FileUploadBaseConfig };
