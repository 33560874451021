import { BaseApiModel, MoneyValueModel } from '@shared/models';

export class EmployeeVoucherModel extends BaseApiModel {
    benefitId = '';
    benefitName = '';
    code = '';
    amount: MoneyValueModel = new MoneyValueModel();
    purchasedAt!: number;
    logo?: string;
}
