import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { COOKIE_KEYS } from '@core/config/cookie-keys';

@Injectable({
    providedIn: 'root'
})
export class UiService {
    /* eslint-disable @typescript-eslint/member-ordering */
    sidebarOpen = false;
    protected _sidebarOpen = new BehaviorSubject<boolean>(this.sidebarOpen);
    $sidebarStateUpdated = this._sidebarOpen.asObservable();
    /* eslint-enable @typescript-eslint/member-ordering */

    constructor(protected cookieService: CookieService) {
        this.sideBarInit();
    }

    updateSidebarState(state: boolean) {
        this._sidebarOpen.next(state);
        this.cookieService.set(COOKIE_KEYS.SIDEBAR_STATE, JSON.stringify(state), undefined, '/');
    }

    protected sideBarInit() {
        const sidebarState = this.getSidebarStateFromCookie();
        this.updateSidebarState(sidebarState);
    }

    protected getSidebarStateFromCookie(): boolean {
        const hasCookie = this.cookieService.check(COOKIE_KEYS.SIDEBAR_STATE);
        return hasCookie ? JSON.parse(this.cookieService.get(COOKIE_KEYS.SIDEBAR_STATE)) : false;
    }
}
