<p-confirmDialog key="epx-confirm" styleClass="epx-confirm epx-confirm-dialog" #cd [style]="{ width: 'fit-content' }" [header]="cd.header">
    <ng-template pTemplate="footer">
        <div class="flex flex-row justify-content-end">
            <app-epx-button-stack>
                <p-button styleClass="epx-button epx-button-secondary" [label]="cd.rejectLabel || 'BUTTONS.NO' | translate" (onClick)="cd.reject()">
                    <ng-template pTemplate="icon">
                        <epx-svg-icon icon="plus" rotation="45deg"></epx-svg-icon>
                    </ng-template>
                </p-button>
                <p-button styleClass="epx-button" [label]="cd.acceptLabel || 'BUTTONS.YES' | translate" (onClick)="cd.accept()">
                    <ng-template pTemplate="icon">
                        <epx-svg-icon icon="check_mark"></epx-svg-icon>
                    </ng-template>
                </p-button>
            </app-epx-button-stack>
        </div>
    </ng-template>
</p-confirmDialog>
