import { Component, Input } from '@angular/core';
import { ContactModel } from '@shared/models/contact.model';

@Component({
    selector: 'app-contact-links',
    templateUrl: './contact-links.component.html',
    styleUrls: ['./contact-links.component.scss']
})
export class ContactLinksComponent {
    @Input() contact: ContactModel = new ContactModel();
}
