import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, QueryParamsHandling, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ApplicationLinks } from '@modules/subsidy/constants';
import { EmployeeLinks } from '@modules/employee/constants';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private history: string[] = [];

    constructor(
        public router: Router,
        private location: Location,
        private activeRoute: ActivatedRoute
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.history.push(event.urlAfterRedirects);
            }
        });
    }

    navigatePath(...names: string[]): Promise<boolean> {
        return this.router.navigate(names);
    }

    navigateUrl(name: string): Promise<boolean> {
        return this.router.navigateByUrl(name);
    }

    goToLogin(): Promise<boolean> {
        return this.navigatePath('auth');
    }

    goToDashboard(): Promise<boolean> {
        return this.navigatePath('/dashboard');
    }

    goToCustomerDetails(id: string) {
        this.router.navigate(['/customers', id]);
    }

    goToEmployeeList() {
        this.router.navigate([EmployeeLinks.main]);
    }

    goToEmployeeDetails(employeeId: string) {
        this.router.navigate([EmployeeLinks.main, employeeId]);
    }

    goToApplicationList() {
        this.router.navigate([ApplicationLinks.main]);
    }

    goToApplicationDetails(applicationId: string) {
        this.router.navigate([ApplicationLinks.main, applicationId]);
    }

    goToBenefitList() {
        this.router.navigate(['/benefits']);
    }

    goToBenefitDetails(benefitId: string) {
        this.router.navigate(['/benefits', benefitId]);
    }

    historyProceed(): void {
        this.history.pop();
        history.length > 0 ? this.location.forward() : this.router.navigateByUrl('/');
    }

    historyGoBack(): void {
        this.history.pop();
        history.length > 0 ? this.location.back() : this.router.navigateByUrl('/');
    }

    updateQueryParams(params: Params, handling?: QueryParamsHandling, commands?: string[], relation?: ActivatedRoute) {
        return this.router.navigate(commands || [], {
            relativeTo: relation || this.activeRoute,
            queryParams: params,
            queryParamsHandling: handling || 'merge'
        });
    }
}
