import { Pipe, PipeTransform } from '@angular/core';
import { DateTime, Info, UnitLength } from 'luxon';
import { LocaleOptions } from 'luxon/src/datetime';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '@core/services';

@Pipe({
    name: 'epxDateFromMillis'
})
class EpxDateFromMillisPipe implements PipeTransform {
    transform(value: number | undefined | null, format: string = 'dd.MM.yyyy', opts: LocaleOptions = { locale: 'de-DE' }): string {
        if (!value) return '';
        return DateTime.fromMillis(value).toFormat(format, opts);
    }
}

@Pipe({
    name: 'epxStarEndDate'
})
class EpxStartEndDatePipe implements PipeTransform {
    constructor(protected translate: TranslateService) {}
    transform(startDate: number | undefined | null, endDate: number | undefined | null, format: string = 'dd.MM.yy', opts: LocaleOptions = { locale: 'de-DE' }): string {
        if (!startDate || !endDate) return this.translate.instant('DATE.INVALID_DATE');
        const startString = DateTime.fromMillis(startDate).toFormat(format, opts);
        const endString = DateTime.fromMillis(endDate).toFormat(format, opts);
        return [startString, endString].join(' - ');
    }
}

@Pipe({
    name: 'epxMonthDurationPipe'
})
class EpxMonthDurationPipe implements PipeTransform {
    constructor(protected translate: TranslateService) {}
    transform(duration: number | undefined): string {
        if (!duration) return this.translate.instant('DATES.INVALID_DATE');
        const suffix = duration === 1 ? this.translate.instant('DATES.MONTH.SINGULAR') : this.translate.instant('DATES.MONTH.PLURAL');
        return `${duration} ${suffix}`;
    }
}

@Pipe({
    name: 'epxMonthNamePipe'
})
class EpxMonthNamePipe implements PipeTransform {
    constructor(protected language: LanguageService) {}
    transform(month: number, format: UnitLength = 'short'): string {
        return Info.months(format, { locale: this.language.currentLang.locale })[month - 1];
    }
}

export { EpxDateFromMillisPipe, EpxStartEndDatePipe, EpxMonthDurationPipe, EpxMonthNamePipe };
