import { Observable, Subscription } from 'rxjs';
import { UserModel } from '@shared/models/user.model';

export class LayoutViewModel {
    sidebarOpen = false;
    user: UserModel = new UserModel();
    sidebarOpenSubscription!: Subscription;
    userSubscription!: Subscription;

    initialState(sidebarObserver: Observable<boolean>, userObserver: Observable<UserModel>) {
        this.sidebarOpenSubscription = sidebarObserver.subscribe((state) => (this.sidebarOpen = state));
        this.userSubscription = userObserver.subscribe(this.setUser.bind(this));
    }

    destroy() {
        this.sidebarOpenSubscription.unsubscribe();
        this.userSubscription.unsubscribe();
    }

    protected setUser(user: UserModel) {
        this.user = user;
    }
}
