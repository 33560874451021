import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TSubsidyType } from '@shared/types';

@Pipe({
    name: 'subsidyType'
})
export class SubsidyTypePipe implements PipeTransform {
    constructor(private translate: TranslateService) {}

    transform(value: TSubsidyType | undefined): string {
        const uValue = value?.toUpperCase() || 'EMPTY';
        return this.translate.instant(`MODULE_BENEFIT.BENEFIT_SUBSIDY_TYPE.${uValue}`);
    }
}
