import { Component, Input } from '@angular/core';
import { TIconType } from '@shared/components/ui';

@Component({
    selector: 'app-epx-ext-link-button',
    template: `
        <a class="epx-ext-link-button" [ngClass]="[getIconPos(), disabled ? 'link-disabled' : '']" [href]="href" [target]="target">
            <epx-svg-icon *ngIf="icon" [icon]="icon"></epx-svg-icon>
            <span [innerHTML]="label"></span>
        </a>
    `,
    styleUrls: ['./epx-ext-link-button.component.scss']
})
export class EpxExtLinkButtonComponent {
    @Input() iconPos: 'left' | 'right' = 'right';
    @Input() label = '';
    @Input() icon: TIconType | undefined;
    @Input() disabled = false;
    @Input() href = 'https://emplu.de';
    @Input() target: '_blank' | '_parent' | '_self' | 'top' = '_blank';

    getIconPos(): string {
        return this.iconPos === 'left' ? '' : 'flex-row-reverse';
    }
}
