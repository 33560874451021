import { Component, Input } from '@angular/core';

@Component({
    selector: 'epx-avatar-component',
    templateUrl: 'epx-avatar.component.html',
    styleUrls: ['epx-avatar.component.scss']
})
export class EpxAvatarComponent {
    @Input() name?: string;
    @Input() imageSrc?: string;
    @Input() size = 40;
    @Input() bgColor = '#323232';
    @Input() fgColor = '#ffffff';
}
