<p-confirmDialog key="epx-error-dialog" styleClass="epx-confirm epx-error-dialog" #errorDialog [style]="{ width: 'fit-content', minWidth: '20rem' }">
    <ng-template pTemplate="header">
        <section class="w-full flex flex-row justify-content-between">
            <div class="flex flex-row align-items-center justify-content-start font-bold gap-2">
                <epx-svg-icon icon="alert_triangle"></epx-svg-icon>
                <span [style.line-height.px]="20" [innerHTML]="'GENERAL.ERROR' | translate"></span>
            </div>
            <button (click)="errorDialog.accept()" pButton class="epx-button epx-button-text">
                <epx-svg-icon color="White" icon="x"></epx-svg-icon>
            </button>
        </section>
    </ng-template>
    <ng-template pTemplate="message" let-message>
        <div class="flex flex-column align-items-start w-full gap-3 p-5">
            <h5 [innerHTML]="'GENERAL.ERROR_SORRY' | translate"></h5>
            <span>{{ message.message }}</span>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <div class="flex flex-row justify-content-end w-full">
            <!--
          <button pButton class="epx-button w-full" label="Support kontaktieren"></button>
-->
        </div>
    </ng-template>
</p-confirmDialog>
