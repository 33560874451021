import { IAccountingSoftware } from '@shared/models';

const AccountingSoftware: IAccountingSoftware[] = [
    {
        value: 'agenda',
        label: 'Agenda'
    },
    {
        value: 'lodas',
        label: 'LODAS'
    },
    {
        value: 'datev',
        label: 'DATEV L&G'
    }
];

export { AccountingSoftware };
