import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-dashboard-topbar',
    templateUrl: './dashboard-topbar.component.html',
    styleUrls: ['./dashboard-topbar.component.scss']
})
export class DashboardTopbarComponent {
    @Input() sidebarOpen = false;
}
