import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UiService } from '@services/ui.service';
import { TranslateService } from '@ngx-translate/core';
import { IMenuItemExtended } from '@shared/interfaces';

@Component({
    selector: 'app-dashboard-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent {
    topMenu: IMenuItemExtended[];
    @Input() theme: 'light' | 'dark' = 'light';
    @Input() sidebarOpen = false;
    @Output() sidebarToggleEvent = new EventEmitter();

    constructor(
        protected uiService: UiService,
        private translate: TranslateService
    ) {
        this.topMenu = [
            {
                label: 'MAIN_NAVIGATION.DASHBOARD',
                id: 'sidenav-dashboard-link',
                svgIcon: 'dashboard',
                routerLink: '/dashboard'
            },
            {
                label: 'MODULE_BENEFIT.BENEFIT.PLURAL',
                id: 'sidenav-benefits-link',
                svgIcon: 'benefits',
                routerLink: '/benefits'
            },
            {
                label: 'MODULE_BENEFIT.TYPES.SUBSIDY.PLURAL',
                id: 'sidenav-subsidies-link',
                svgIcon: 'subsidies',
                routerLink: '/applications'
            },
            {
                label: 'MODULE_EMPLOYEE.EMPLOYEE.PLURAL',
                id: 'sidenav-employees-link',
                svgIcon: 'user',
                routerLink: '/employees'
            },
            {
                label: 'MODULE_EXPORTS.EXPORTS.PLURAL',
                id: 'sidenav-exports-link',
                svgIcon: 'download',
                routerLink: 'exports'
            },
            {
                label: 'MODULE_REPORTS.REPORTS.PLURAL',
                id: 'sidenav-reports-link',
                svgIcon: 'reports',
                //routerLink: '/reports',
                disabled: true,
                isPreview: true,
                tooltip: translate.instant('MODULE_REPORTS.TOOLTIP')
            }
        ];
    }
}
