import { BaseApiModel, MoneyValueModel } from '@shared/models';
import { ContactModel } from '@shared/models/contact.model';
import { TInternalBenefitCategory, TSubsidyType } from '@shared/types';
import { IFile } from '@shared/interfaces';
import { BenefitDescriptionsModel } from '@modules/benefit/models/benefit-descriptions.model';

export interface IBenefitSubscription {
    duration: string;
    durationUnit: string;
    price: MoneyValueModel;
    description: string;
}

class BenefitModel extends BaseApiModel {
    name = '';
    category = '';
    empluCategory!: TInternalBenefitCategory;
    publishByDefault!: boolean;
    isFringeBenefit!: boolean;
    requirements?: string[];
    tags: string[] = [];
    attachments?: IFile[];
    actionButtonLabel?: string;
    contact: ContactModel = new ContactModel();
    support: ContactModel = new ContactModel();
    customerConfigs: BenefitGeneralConfigModel[] = [];
    descriptions: BenefitDescriptionsModel = new BenefitDescriptionsModel();
    durationText?: string;
    externalLink?: string;
    headerImage?: string;
    icon?: string;
    isPromoted?: boolean;
    logo?: string;
    minAppVersion?: string;
    promotionSortOrder?: number | null;
    subscription?: IBenefitSubscription;
    subsidyType?: TSubsidyType;
    vouchersAvailable?: number;
    voucherValue: MoneyValueModel = new MoneyValueModel();
    taxInfo?: string | undefined;
    isActive = true;
}

class BenefitGeneralConfigModel {
    id?: string;
    key = '';
    displayName = '';
    required = false;
    isActive?: boolean;
    description?: string;
}

class CreateBenefitModel {
    name = '';
    category = '';
    empluCategory: TInternalBenefitCategory = 'voucher';
    subsidyType?: TSubsidyType;
}

export { BenefitModel, BenefitGeneralConfigModel, CreateBenefitModel };
