<p-dialog
    (onHide)="onHide()"
    [(visible)]="dialogVisible"
    [modal]="modal"
    [dismissableMask]="dismissibleMask"
    [position]="position"
    [resizable]="resizable"
    [draggable]="draggable"
    [closable]="closable"
    [id]="id"
    [baseZIndex]="2000"
    [styleClass]="getStyleClass"
    [style]="style">
    <ng-template *ngIf="headerVisible" pTemplate="header">
        <h3 [innerHTML]="headerLabel || ''"></h3>
    </ng-template>
    <section class="epx-dialog-content" [ngClass]="{ 'stretched-content': isStretched }" [style.padding-top]="paddingTop">
        <ng-content select="[dialog-content]"></ng-content>
    </section>
    <ng-template *ngIf="footerVisible" pTemplate="footer">
        <ng-content select="[dialog-footer]"></ng-content>
    </ng-template>
</p-dialog>
