import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { COOKIE_KEYS } from '@core/config/cookie-keys';
import { BehaviorSubject } from 'rxjs';
import { DateTime } from 'luxon';

export type TLangAvailable = 'de' | 'en';
export type TLocale = 'de-DE' | 'en-US';

export interface ILanguageMeta {
    shortLabel: string;
    label: string;
    identifier: TLangAvailable;
    locale: TLocale;
}

export const languages: ILanguageMeta[] = [
    {
        label: 'deutsch',
        shortLabel: 'de',
        identifier: 'de', // TODO: Save Remove in favour of locale
        locale: 'de-DE'
    },
    {
        label: 'english',
        shortLabel: 'en',
        identifier: 'en', // TODO: Save Remove in favour of locale
        locale: 'en-US'
    }
];

// TODO - rework that to use signals
@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    /* eslint-disable @typescript-eslint/member-ordering */
    language: ILanguageMeta = languages[0];
    protected _language: BehaviorSubject<ILanguageMeta> = new BehaviorSubject<ILanguageMeta>(this.language);
    $language = this._language.asObservable();

    /* eslint-enable @typescript-eslint/member-ordering */

    constructor(
        protected translate: TranslateService,
        protected cookieService: CookieService
    ) {
        this.loadLanguage();
    }

    get availableLanguages(): ILanguageMeta[] {
        return languages;
    }

    get currentLang(): ILanguageMeta {
        const identifier = this.translate.currentLang || this.translate.defaultLang;
        return this.langMeta(identifier);
    }

    initialLang() {
        this.setLanguage('de');
    }

    setLanguage(lang: string) {
        const langMeta = this.langMeta(lang);
        if (!langMeta) return;
        else {
            this.translate.setDefaultLang('de');
            this.translate.use(lang).subscribe(() => this.handleLanguageChanged(langMeta));
            this._language.next(langMeta);
        }
    }

    protected handleLanguageChanged(lang: ILanguageMeta) {
        const expirationDate = DateTime.now().plus({ year: 1 }).valueOf();
        this.cookieService.set(COOKIE_KEYS.USER_LANG_KEY, lang.identifier, expirationDate, '/');
        this._language.next(lang);
        this.language = lang;
    }

    protected langMeta(lang: string): ILanguageMeta {
        return languages.find((item) => item.identifier === lang) || languages[0];
    }

    protected loadLanguage() {
        if (this.cookieService.check(COOKIE_KEYS.USER_LANG_KEY)) {
            const lang = this.cookieService.get(COOKIE_KEYS.USER_LANG_KEY);
            this.setLanguage(lang);
        }
    }
}
