import { BaseApiModel } from '@shared/models/base-api.model';
import { SessionModel } from '@shared/models/session.model';

export type TUSerPosition = 'Master' | undefined;
export type TUserRole = 'admin:internal' | 'manager:internal' | 'admin:customer' | 'manager:customer' | 'employee:customer' | undefined;

export class UserModel extends BaseApiModel {
    email = '';
    position: TUSerPosition;
    role?: TUserRole;
    firstname = '';
    lastname = '';
    phone = '';
    accountType = ''; // TODO Add Types
    companyId = '';
    deletionPending = false;
    staffNumber = '';
    userDeletionDate: number | null = null;
    session?: SessionModel = new SessionModel();
    avatar?: string;
}
