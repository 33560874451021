<section class="_module-layout-wrapper _epx-paper-card _no-padding" [class._background]="hasBackground" [class.has-divider]="hasDivider">
    <section *ngIf="hasHeader" class="_module-layout-header">
        <div class="_header-start">
            <p-button *ngIf="canGoBack" (onClick)="this.goBack()" styleClass="epx-button epx-button-secondary epx-icon-button epx-icon-only-btn" iconPos="right">
                <epx-svg-icon icon="chevron_left"></epx-svg-icon>
            </p-button>
            <h3 *ngIf="headerLabel" [innerHTML]="headerLabel || ' - '"></h3>
        </div>
        <div class="_header-end">
            <h3 *ngIf="headerAdditionalLabel" [innerHTML]="headerAdditionalLabel || ' - '"></h3>
            <ng-content select="[header-end-content]"></ng-content>
        </div>
    </section>
    <section class="_module-layout-content">
        <ng-content></ng-content>
    </section>
</section>
