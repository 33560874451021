import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-epx-range-selector',
    template: `
        <div class="flex flex-row align-items-center gap-2">
            <p-button (onClick)="prevEmitter.emit(label)" styleClass="epx-button epx-button-text">
                <ng-template pTemplate="icon">
                    <epx-svg-icon icon="chevron_left" color="Subtile"></epx-svg-icon>
                </ng-template>
            </p-button>
            <label [style.min-width.rem]="7" class="justify-content-center">{{ label }}</label>
            <p-button (onClick)="nextEmitter.emit(label)" styleClass="epx-button epx-button-text">
                <ng-template pTemplate="icon">
                    <epx-svg-icon icon="chevron_right" color="Subtile"></epx-svg-icon>
                </ng-template>
            </p-button>
        </div>
    `,
    styleUrls: ['./epx-range-selector.component.scss']
})
export class EpxRangeSelectorComponent {
    @Input() label = '';
    @Output() nextEmitter = new EventEmitter<string>();
    @Output() prevEmitter = new EventEmitter<string>();
}
