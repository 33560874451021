import { Table } from 'primeng/table';
import { ITableData } from '@shared/interfaces';

export class TableBaseComponent {
    table: Table | undefined;
    tableColumns: ITableData[] = [];

    applyFilterGlobal(table: Table, $event: any, matchMode: any) {
        table?.filterGlobal(($event.target as HTMLInputElement).value, matchMode);
    }

    clear(table: Table, field: HTMLInputElement) {
        table?.clear();
        field.value = '';
    }
}
