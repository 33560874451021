import { Component } from '@angular/core';
import { environment } from 'environments/environment';
import { appInfo } from 'app.info';

@Component({
    selector: 'epx-env-indicator',
    templateUrl: './env-indicator.component.html',
    styleUrl: './env-indicator.component.scss'
})
export class EnvIndicatorComponent {
    appInfo = appInfo;

    get isProd() {
        return environment.production;
    }
}
