import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-epx-ref-link',
    template: `
        <a [href]="href | refLinkPipe: type" [target]="target">
            <epx-svg-icon [size]="12" *ngIf="icon" icon="arrow_reference"></epx-svg-icon>
            <span [innerHTML]="label || href"></span>
        </a>
    `,
    styleUrls: ['./epx-ref-link.component.scss']
})
export class EpxRefLinkComponent {
    @Input() label = '';
    @Input() href = '';
    @Input() type: 'email' | 'tel' | 'web' = 'web';
    @Input() target: '_blank' | '_self' | '_parent' | 'top' = '_blank';
    @Input() icon = false;
}
