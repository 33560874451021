import { Component, Input } from '@angular/core';
import { ContactModel } from '@shared/models/contact.model';

@Component({
    selector: 'app-contact-person',
    templateUrl: './contact-person.component.html',
    styleUrls: ['./contact-person.component.scss']
})
export class ContactPersonComponent {
    @Input() contact: ContactModel = new ContactModel();
    @Input() header = '';
}
