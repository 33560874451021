import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ApplicationModel } from '@modules/subsidy/models';
import { Table } from 'primeng/table';
import { TableBaseComponent } from '@shared/components/table/TableBase.component';
import { TApplicationStatus } from '@shared/types';

@Component({
    selector: 'app-application-table',
    templateUrl: './application-table.component.html',
    styleUrls: ['./application-table.component.scss']
})
export class ApplicationTableComponent extends TableBaseComponent {
    @Input() applications: ApplicationModel[] = [];
    @Input() dataLoading = true;
    @Input() filterActive = false;
    @Output() toggleFilter = new EventEmitter();
    @Output() selectApplication = new EventEmitter<ApplicationModel>();
    @ViewChild('applicationTable') applicationTable: Table | undefined;
    statuses: TApplicationStatus[] = ['approved', 'declined', 'waiting-for-applicant', 'waiting-for-employer', 'canceled', 'expired', 'new'];

    constructor() {
        super();
        this.tableColumns = [
            {
                id: 'application-applicant-name',
                header: 'MODULE_EMPLOYEE.EMPLOYEE.SINGULAR',
                field: 'applicant.firstname',
                width: 25
            },
            {
                id: 'application-created',
                header: 'MODULE_SUBSIDY.APPLICATION.TABLE.APPLICATION_DATE',
                field: 'createdAt',
                sortable: true,
                width: 15
            },
            {
                id: 'application-updated',
                header: 'MODULE_SUBSIDY.APPLICATION.TABLE.APPLICATION_UPDATED',
                field: 'updatedAt',
                sortable: true,
                width: 15
            },

            {
                id: 'application-name',
                header: 'MODULE_BENEFIT.TYPES.SUBSIDY.SINGULAR',
                field: 'name',
                sortable: true,
                width: 20
            },

            {
                id: 'application-amount',
                header: 'MONEY.AMOUNT',
                field: 'subsidyAmount',
                width: 15
            },
            {
                header: 'GENERAL.STATUS',
                id: 'application-status',
                field: 'status'
            }
        ];
    }
}
