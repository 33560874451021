import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContactModel } from '@shared/models/contact.model';
import { RegexConstants, ValidationMessagesMandatory, ValidationMessagesPatterns } from '@shared/constants';

@Component({
    selector: 'app-contact-data-form',
    templateUrl: './contact-data-form.component.html',
    styleUrls: ['./contact-data-form.component.scss']
})
export class ContactDataFormComponent {
    @Input() data: ContactModel = new ContactModel();
    @Input() type: 'support' | 'contact' = 'contact';
    @Output() updateContactData = new EventEmitter<ContactModel>();
    protected readonly RegexConstants = RegexConstants;
    protected readonly ValidationMessagesPatterns = ValidationMessagesPatterns;
    protected readonly ValidationMessagesMandatory = ValidationMessagesMandatory;

    submit() {
        this.updateContactData.emit(this.data);
    }
}
