import { Component, Input } from '@angular/core';
import { TIconType } from '@shared/components/ui';

@Component({
    selector: 'epx-tag',
    template: `
        <p-tag [rounded]="rounded" styleClass="epx-tag">
            <epx-svg-icon *ngIf="icon" [icon]="icon"></epx-svg-icon>
            <span class="epx-tag-label" [innerHTML]="label"></span>
        </p-tag>
    `,
    styleUrls: ['./epx-tag.component.scss']
})
export class EpxTagComponent {
    @Input() label: string | undefined = '';
    @Input() icon?: TIconType;
    @Input() rounded = false;
}

@Component({
    selector: 'epx-tag-list',
    template: `
        <section class="flex justify-content-start flex-wrap gap-2">
            <ng-content></ng-content>
        </section>
    `,
    styleUrls: ['./epx-tag.component.scss']
})
export class EpxTagListComponent {}
