enum EmployeeLinks {
    main = '/employees',
    general = 'general',
    budget = 'budget',
    applications = 'applications',
    vouchers = 'vouchers',
    history = 'history'
}

export { EmployeeLinks };
