import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@services/auth.service';
import { BaseApiService } from '@services/base-api.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FileService extends BaseApiService {
    constructor(
        protected http: HttpClient,
        private authService: AuthService
    ) {
        super();
        this.buildUrl('ms-files');
    }

    upload(url: string, formData: FormData): Observable<any> {
        const options = { reportProgress: true, observe: 'events' as 'body', responseType: 'json' as const };
        return this.http.post(url, formData, this.options(this.authService.sessionToken(), options));
    }

    // TODO => UNIFY =>  Use Full DownloadPath
    getFileByStorageName(url: string, storageName: string): Observable<Blob> {
        const options = { reportProgress: true, responseType: 'blob' };
        return this.http.get<Blob>(`${url}/${storageName}`, this.options(this.authService.sessionToken(), options));
    }

    // TODO: Refactor to use Storage Name => Adapt Attachment
    getBlobFileByName(fileName: string): Observable<Blob> {
        const options = { reportProgress: true, responseType: 'blob' };
        return this.http.get<Blob>(`${this.apiUrl}/subsidies/application-file/${fileName}`, this.options(this.authService.sessionToken(), options));
    }
}
