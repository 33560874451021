import { Injectable, signal } from '@angular/core';
import { BaseApiService, FileService, NavigationService, TAPiServices } from '@core/services';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomerModel } from '@modules/customer/models';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@services/auth.service';
import { PayrollAccountNumbers, PayrollDesignationsModel } from '@shared/models';
import { ApplicationModel } from '@modules/subsidy/models';
import { MessagesService } from '@services/messages.service';
import { CookieService } from 'ngx-cookie-service';
import { COOKIE_KEYS } from '@core/config';

@Injectable({
    providedIn: 'root'
})
export class CustomerService extends BaseApiService {
    /* eslint-disable @typescript-eslint/member-ordering */
    service: TAPiServices = 'ms-customer';
    customerAPIUrl = '';
    customer: CustomerModel = new CustomerModel();
    customerName = '';
    customerId = '';
    selectedApplication: ApplicationModel = new ApplicationModel();
    protected _customer = new BehaviorSubject<CustomerModel>(this.customer);
    protected _selectedApplication = new BehaviorSubject<ApplicationModel>(this.selectedApplication);
    protected _customerName = signal<string>(this.customerName);
    protected _customerId = signal<string>(this.customerId);
    $selectedApplication = this._selectedApplication.asObservable();
    $customer = this._customer.asObservable();
    $customerName = this._customerName.asReadonly();
    $customerId = this._customerId.asReadonly();
    /* eslint-enable @typescript-eslint/member-ordering */

    constructor(
        protected http: HttpClient,
        public authService: AuthService,
        protected navigation: NavigationService,
        protected messages: MessagesService,
        protected fileService: FileService,
        protected cookieService: CookieService
    ) {
        super();
        this.buildUrl(this.service);
        this.customerAPIUrl = `${this.apiUrl}`;
    }

    deleteEmployee(id: string | undefined): Observable<boolean> {
        return this.http.delete<boolean>(`${this.customerAPIUrl}/employee/${id}`, this.options(this.authService.sessionToken()));
    }

    getApplications(): Observable<ApplicationModel[]> {
        return this.http.get<ApplicationModel[]>(`${this.customerAPIUrl}/subsidy/application/list`, this.options(this.authService.sessionToken()));
    }

    getApplicationById(id: string) {
        return this.http.get<ApplicationModel>(`${this.customerAPIUrl}/subsidy/application/${id}`, this.options(this.authService.sessionToken())).subscribe({
            next: this.updateApplication.bind(this),
            error: console.error
        });
    }

    applicationAccept(application: ApplicationModel) {
        const { _id } = application;
        this.http.patch<ApplicationModel>(`${this.customerAPIUrl}/subsidy/application/accept`, { _id }, this.options(this.authService.sessionToken())).subscribe({
            next: (data) => {
                this.updateApplication(data);
                this.messages.showSuccess('MODULE_SUBSIDY.APPLICATION.ACTIONS.APPLICATION_ACCEPTED');
            },
            error: console.error
        });
    }

    applicationDecline(application: ApplicationModel) {
        const { _id, comment } = application;
        this.http.patch<ApplicationModel>(`${this.customerAPIUrl}/subsidy/application/decline`, { _id, comment }, this.options(this.authService.sessionToken())).subscribe({
            next: (data) => {
                this.updateApplication(data);
                this.messages.showSuccess('MODULE_SUBSIDY.APPLICATION.ACTIONS.APPLICATION_DECLINED');
            },
            error: console.error
        });
    }

    applicationRequestChanges(application: ApplicationModel) {
        const { _id, comment } = application;
        this.http.patch<ApplicationModel>(`${this.customerAPIUrl}/subsidy/application/request-change`, { _id, comment }, this.options(this.authService.sessionToken())).subscribe({
            next: (data) => {
                this.updateApplication(data);
                this.messages.showSuccess('MODULE_SUBSIDY.APPLICATION.ACTIONS.APPLICATION_CHANGES_REQUESTED');
            },
            error: console.error
        });
    }

    getCustomerById(id?: string | null) {
        const _id = id || this.customer._id;
        this.http.get<CustomerModel>(`${this.customerAPIUrl}/company/${_id}`, this.options(this.authService.sessionToken())).subscribe({
            next: this.updateCustomer.bind(this),
            error: console.error
        });
    }

    updateCustomerByID(customer: CustomerModel) {
        this.http.put<CustomerModel>(`${this.customerAPIUrl}/company/${customer._id}`, customer, this.options(this.authService.sessionToken())).subscribe({
            next: this.updateCustomer.bind(this),
            error: console.error
        });
    }

    getDesignationConfig(): Observable<PayrollDesignationsModel[]> {
        return this.http.get<PayrollDesignationsModel[]>(`${this.customerAPIUrl}/configs/company/designations`, this.options(this.authService.sessionToken()));
    }

    updateCustomerDesignations(data: PayrollAccountNumbers) {
        return this.http.put<PayrollAccountNumbers>(`${this.customerAPIUrl}/customer/designations`, data, this.options(this.authService.sessionToken())).subscribe({
            next: () => {
                this.messages.showSuccess();
                this.getCustomerById();
            },
            error: console.error
        });
    }

    updateApplication(application: ApplicationModel) {
        this.selectedApplication = application;
        this._selectedApplication.next(application);
    }

    protected updateCustomer(customer: CustomerModel) {
        if (customer._id) {
            this.cookieService.set(COOKIE_KEYS.CUSTOMER_ID, customer._id);
            this.customer = customer;
            this._customer.next(customer);
            this._customerName.set(customer.name);
            this._customerId.set(customer._id);
        }
    }
}
