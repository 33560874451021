<div class="grid-container">
    <div class="Item_1"></div>
    <div class="item_2"></div>
    <div class="item_3"></div>
    <div class="item_4"></div>
    <div class="item_6"></div>
    <div class="item_7"></div>
    <div class="item_8"></div>
    <div class="item_9"></div>
</div>
