<section>
    <ng-container *ngIf="voucherDisplay">
        <ng-container *ngTemplateOutlet="vouchersHistory"></ng-container>
    </ng-container>
    <ng-container *ngIf="!voucherDisplay">
        <ng-container *ngTemplateOutlet="noData"></ng-container>
    </ng-container>
</section>

<ng-template #vouchersHistory>
    <p-accordion [activeIndex]="-1">
        <ng-container *ngFor="let month of voucherDisplay?.monthList">
            <p-accordionTab>
                <ng-template pTemplate="header">
                    <div class="flex align-items-center justify-content-between w-full">
                        <span [innerHTML]="'MODULE_BENEFIT.GENERAL.VOUCHERS_FOR_MONTH' | translate: { month }"></span>
                        <button (click)="downloadCSV($event, month)" pButton class="epx-button epx-button-secondary" label="Download as CSV"></button>
                    </div>
                </ng-template>
                <app-history-element *ngFor="let voucher of (voucherDisplay?.grouped)![month]" [voucher]="voucher"></app-history-element>
            </p-accordionTab>
        </ng-container>
    </p-accordion>
</ng-template>

<ng-template #noData>
    <div class="w-full h-full">
        <span>No Data Found</span>
    </div>
</ng-template>
