import { BaseApiModel, MoneyValueModel, PayrollAccountNumbers, TAccountingSoftware } from '@shared/models';
import { ContactModel } from '@shared/models/contact.model';
import { AddressModel } from '@shared/models/address.model';

class CustomerModel extends BaseApiModel {
    name = '';
    address: AddressModel = new AddressModel();
    usesStaffNumber = false;
    corporateData = new CorporateDataModel();
    contactPerson: ContactModel = new ContactModel();
    defaultBudget = new MoneyValueModel();
    endOfPayroll?: number;
    contractStartDate?: number;
    status?: string;
    payrollAccounting?: PayrollAccountNumbers;
    wageType?: TAccountingSoftware = undefined;
    consultantNumber?: string;
    clientNumber?: string;
}

class CorporateDataModel {
    headCount = '';
    fullTimeEmployees = 0;
    partTimeEmployees = 0;
    miniJobEmployees = 0;
    freelancers = 0;
    sector = '';
    locations: string[] = [];
    employeesAverageAge = 0;
    averageSalary: MoneyValueModel = new MoneyValueModel();
    fluctuation = 0;
    sickDays = 0;
    worksCouncil = 0;

    get locationsString(): string {
        return this.locations.length ? this.locations.join(', ') : '';
    }
}

export { CustomerModel, CorporateDataModel };
