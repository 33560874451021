import { BaseApiModel, FileAttachment, MoneyValueModel } from '@shared/models';
import { ApplicantModel } from '@modules/subsidy/models/applicant.model';
import { TApplicationStatus } from '@shared/types';

export interface ICommentTimeline {
    date?: number;
    assignee?: string;
    comment: string;
}

class ApplicationModel extends BaseApiModel {
    applicant = new ApplicantModel();
    attachments: FileAttachment[] = [];
    category = '';
    comment: string | undefined;
    companyId = '';
    contractPartnerName = '';
    startOfContract?: number;
    endOfContract?: number;
    startOfSubsidy?: number;
    endOfSubsidy?: number;
    monthlyCost: MoneyValueModel = new MoneyValueModel();
    name = '';
    notes = '';
    status: TApplicationStatus = 'new';
    subsidyAmount: MoneyValueModel = new MoneyValueModel();
    subsidyPeriod?: number;

    productId = '';
    // ticketType?: string;
    // subsidyStartNextMonth = false;

    /*reservedBudget?: {
        amount: MoneyValueModel;
        reservedUntil: number;
    };*/
}

class ApplicationStatusModel {
    value: TApplicationStatus = 'new';
    name = '';
    commentRequired = false;
}

export { ApplicationModel, ApplicationStatusModel };
