import { Component } from '@angular/core';
import { environment } from 'environments/environment';
import { appInfo } from 'app.info';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-app-version',
    templateUrl: './app-version.component.html',
    styleUrl: './app-version.component.scss'
})
export class AppVersionComponent {
    appInfo = appInfo;
    buildVisible = false;

    get isProd() {
        return environment.production;
    }

    get buildTime() {
        if (!appInfo.buildTime) return 'No BuildTime Set';
        return DateTime.fromMillis(appInfo.buildTime).toFormat('dd.MM.yy (HH.mm)');
    }

    toggleBuildVisible() {
        this.buildVisible = !this.buildVisible;
    }
}
