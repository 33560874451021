import { FileAttachment } from '@shared/models/file.model';

interface IExportUpload {
    companyId: string;
    year: number;
    month: number;
    file: File;
}

class ExportModel {
    month = 0;
    file: FileAttachment = new FileAttachment();
}

class ExportList {
    [key: string]: ExportModel[];
}

export { ExportModel, ExportList, IExportUpload };
