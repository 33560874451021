import { Component, Input } from '@angular/core';

@Component({
    selector: 'epx-data-row',
    template: `
        <section class="epx-data-field">
            <span class="epx-data-field-label" [innerHTML]="label | translate"></span>
            <span class="epx-data-field-data" [innerHTML]="data"></span>
        </section>
    `,
    styleUrls: ['./epx-data-row.component.scss']
})
export class EpxDataRowComponent {
    @Input() label = '';
    @Input() data: string | null = '';
}
