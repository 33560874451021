import { BaseApiModel } from '@shared/models/base-api.model';
import { md5Hash } from '@shared/utils';

export class AuthModel extends BaseApiModel {
    password = '';
    email = '';
    hashedPassword = '';

    hashPassword() {
        this.hashedPassword = md5Hash(this.password);
    }

    get hashedPass(): string {
        return md5Hash(this.password);
    }
}
