import { Pipe, PipeTransform } from '@angular/core';
import { MoneyValueModel } from '@shared/models';
import { formatCurrency, getCurrencySymbol, getLocaleCurrencyName } from '@angular/common';

@Pipe({
    name: 'epxCurrency'
})
export class EpxCurrencyPipe implements PipeTransform {
    transform(amount: MoneyValueModel, digitsInfo: string = '1.2-2', locale: string = 'de'): string | null {
        if (amount.value < 0) return ' - ';
        return formatCurrency(amount.value, locale, getCurrencySymbol(amount.currencyCode, 'wide'), amount.currencyCode, digitsInfo);
    }
}

@Pipe({
    name: 'epxCurrencyWithName'
})
export class EpxCurrencyWithName implements PipeTransform {
    transform(amount: MoneyValueModel, digitsInfo: string = '1.2-2', locale: string = 'de'): string | null {
        if (amount.value < 0) return ' - ';
        return formatCurrency(amount.value, locale, <string>getLocaleCurrencyName(locale), amount.currencyCode, digitsInfo);
    }
}
