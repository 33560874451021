import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'voucherCountPipe'
})
export class VoucherCountPipe implements PipeTransform {
    transform(count: number | undefined): string {
        if (typeof count === 'number') return count.toString();
        return 'Nicht genutzt';
    }
}
