import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'epxAbbreviate'
})
class EpxAbbreviatePipe implements PipeTransform {
    transform(value: any[], sliceStart: number = 0, sliceEnd: number = 4, joins: string = ', ', end: string = '...'): string {
        const data = value.slice(sliceStart, sliceEnd);
        return `${data.join(joins)} ${end}`;
    }
}

@Pipe({
    name: 'epxTagAbbreviate'
})
class EpxTagAbbreviate implements PipeTransform {
    constructor(protected translate: TranslateService) {}

    transform(value: string[], length: number = 2): string[] {
        const data = value.slice(0, length);
        const dif = value.length - data.length;
        if (dif < 1) return value;
        return [...data, `+ ${dif} weitere`];
    }
}

export { EpxAbbreviatePipe, EpxTagAbbreviate };
