import { MoneyValueModel } from '@shared/models/money-value.model';

export interface IUsageChartData {
    used: number;
    rest: number;
}

class ReportsDashboardStatsModel {
    usageMonthly: MonthlyUsageModel[] = [];
    employeeCurrent: EmployeeStatisticsModel = new EmployeeStatisticsModel();
}

class MonthlyUsageModel {
    companyId = '';
    monthNumber = 1; // month Number 1 – 12
    year = 2023;
    label = ''; // MMM YYYY Month Year
    monthShortLabel = ''; // Month Short MMM
    yearLabel = '';
    budgetProvided: MoneyValueModel = new MoneyValueModel();
    budgetUsed: MoneyValueModel = new MoneyValueModel();
    budgetUnused: MoneyValueModel = new MoneyValueModel();
    usagePercent = 0;
}

class EmployeeStatisticsModel {
    activeEmployeesTotal = 0;
    perBudget: EmployeesPerBudgetModel[] = [];
}

class EmployeesPerBudgetModel {
    budget: MoneyValueModel = new MoneyValueModel();
    count = 0;
}

export { ReportsDashboardStatsModel, MonthlyUsageModel, EmployeeStatisticsModel, EmployeesPerBudgetModel };
