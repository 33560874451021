import { Component, Input } from '@angular/core';
import { IMenuItemExtended } from '@shared/interfaces';

@Component({
    selector: 'app-epx-menu',
    templateUrl: 'epx-menu.component.html',
    styleUrls: ['epx-menu.component.scss']
})
export class EpxMenuComponent {
    @Input() menuItems: IMenuItemExtended[] = [];
}
