import { Component } from '@angular/core';

@Component({
    selector: 'epx-data-row-stack',
    template: `
        <section class="flex flex-column gap-2">
            <ng-content></ng-content>
        </section>
    `,
    styleUrls: ['./epx-data-row-stack.component.scss']
})
export class EpxDataRowStackComponent {}
