import { Component } from '@angular/core';

@Component({
    selector: 'app-no-data',
    template: `
        <section class="no-data-found">
            <span> Keine Passenden Benefits gefunden</span>
        </section>
    `,
    styleUrls: ['./no-data.component.scss']
})
export class NoDataComponent {}
