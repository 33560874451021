import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EditorTextChangeEvent } from 'primeng/editor';

@Component({
    selector: 'epx-editor-app',
    template: `
        <section class="editor-wrapper">
            <div class="editor-container">
                <p-editor [modules]="" [style]="{ height: '50vh' }" (onTextChange)="emitTextChange($event)" #editor="ngModel" [(ngModel)]="editorText">
                    <ng-template pTemplate="header">
                        <div class="ql-formats">
                            <button type="button" class="ql-bold" aria-label="Bold"></button>
                            <button type="button" class="ql-italic" aria-label="Italic"></button>
                            <button type="button" class="ql-underline" aria-label="Underline"></button>
                            <button type="button" value="bullet" class="ql-list" aria-label="Unordered List"></button>
                            <button type="button" value="ordered" class="ql-list" aria-label="Ordered List"></button>
                            <button type="button" class="ql-clean" aria-label="Clear Format"></button>
                        </div>
                        <span class="ql-format-separator"></span>
                        <div class="ql-formats">
                            <select class="ql-align">
                                <option value=""></option>
                                <option value="center"></option>
                                <option value="right"></option>
                                <option value="justify"></option>
                            </select>
                        </div>
                    </ng-template>
                </p-editor>
            </div>
            <div class="editor-counter">
                <div>
                    <span>{{ rawText.length || 0 }}</span>
                </div>
            </div>
        </section>
    `,
    styleUrls: ['./editor.component.scss']
})
export class EditorComponent {
    @Input() editorText = '';
    @Input() height = 10;
    @Output() textChangeEmitter: EventEmitter<EditorTextChangeEvent> = new EventEmitter();
    rawText = '';

    emitTextChange(event: EditorTextChangeEvent) {
        this.rawText = event.textValue;
        this.textChangeEmitter.emit(event);
    }
}
