export interface ILangDescription {
    de: string;
    en: string;
}

export class BenefitDescriptionsModel {
    shortDescription: ILangDescription = {} as ILangDescription;
    description: ILangDescription = {} as ILangDescription;
    employerShortDescription: ILangDescription = {} as ILangDescription;
    employerDescription: ILangDescription = {} as ILangDescription;
    howToDescription: ILangDescription = {} as ILangDescription;
    infoMaterialDownload = '';
}
