import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'epx-generic-dialog',
    templateUrl: './epx-generic-dialog.component.html',
    styleUrls: ['./epx-generic-dialog.component.scss']
})
export class EpxGenericDialogComponent {
    dialogVisible = true;
    @Input() id = 'generic-dialog';
    @Input() style = {};
    @Input() minWidth?: number = 30;
    @Input() modal = true;
    @Input() dismissibleMask = true;
    @Input() closable = true;
    @Input() resizable = false;
    @Input() draggable = false;
    @Input() styleClass = '';
    @Input() headerVisible = true;
    @Input() headerLabel = '';
    @Input() footerVisible = true;
    @Input() isError = false;
    @Input() isStretched = false;
    @Input() position: 'center' | 'top' | 'bottom' | 'left' | 'right' | 'topleft' | 'topright' | 'bottomleft' | 'bottomright' = 'center';
    @Input() paddingTop = 30;
    @Output() hideDialogEmitter = new EventEmitter<boolean>();

    onHide() {
        this.hideDialogEmitter.emit(false);
    }

    get getStyleClass() {
        return `epx-dialog ${this.styleClass} ${this.isError && '_error-dialog'} ${!this.headerVisible && '_headerLess'} ${!this.footerVisible && '_footerLess'}`;
    }
}
