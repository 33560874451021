import { IConfiguration } from './env.config';

export const environment: IConfiguration = {
    production: false,
    name: 'development',
    apiUrl: '',
    apiSubPath: '-dev',
    apiDomain: '.emplu.de',
    apiVersion: '/api/v1',
    apiProtocol: 'https://'
};
