import { Component, Input } from '@angular/core';

@Component({
    selector: 'epx-required-label',
    template: ' <span [innerHTML]="label | translate"></span> ',
    styleUrls: ['./epx-required-label.component.scss']
})
export class EpxRequiredLabelComponent {
    @Input() label = 'VALIDATION.REQUIRED';
}
