import { LOCALE_ID, NgModule } from '@angular/core';
import localeDe from '@angular/common/locales/de';
import { CommonModule, NgOptimizedImage, registerLocaleData } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
    DashboardSkeletonComponent,
    EpxAvatarComponent,
    EpxButtonStackComponent,
    EpxCircleProgressComponent,
    EpxConfirmDialogComponent,
    EpxConfirmPopupComponent,
    EpxErrorDialogComponent,
    EpxExtLinkButtonComponent,
    EpxGenericDialogComponent,
    EpxLangSelectComponent,
    EpxLinkButtonComponent,
    EpxLoaderCircleComponent,
    EpxLoaderFluidCircleComponent,
    EpxLoaderProgressDotsComponent,
    EpxMenuComponent,
    EpxPreLoaderComponent,
    EpxRangeSelectorComponent,
    EpxSvgIconComponent,
    EpxSvgUrlPipe,
    EpxTagComponent,
    EpxTagListComponent,
    MessagesComponent,
    NoDataComponent
} from '@shared/components/ui';
import { PrimeNgModule } from '@app/vendor/primeng.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarModule } from 'ngx-avatars';
import { UserProfileMenuComponent } from '@shared/components/user';
import { EpxModuleLayoutComponent } from '@app/layout';
import { FileUploadComponent } from '@shared/components/files';
import { HistoryElementComponent, VoucherHistoryComponent } from '@shared/components/voucher';
import { ApplicationStatusTagComponent, ApplicationTableComponent } from '@shared/components/subsidy';
import { EpxEndOfMonthDirective } from '@shared/components/directives';
import { ContactDataFormComponent, EditorComponent, EpxDataRowComponent, EpxDataRowStackComponent, EpxRequiredLabelComponent, RichTextEditorComponent } from '@shared/components/form';
import { ContactLinksComponent, ContactPersonComponent, FooterComponent, StretchedModuleContentComponent } from '@shared/components/general';
import {
    ApplicationStatusNamePipe,
    BGColorForStatusPipe,
    ColorForStatusPipe,
    DescriptionKeyPipe,
    DesignationNamesPipe,
    EpxAbbreviatePipe,
    EpxCurrencyPipe,
    EpxCurrencyWithName,
    EpxDateFromMillisPipe,
    EpxFullNamePipe,
    EpxMonthDurationPipe,
    EpxMonthNamePipe,
    EpxPluralizationPipe,
    EpxStartEndDatePipe,
    EpxTagAbbreviate,
    EpxUserRolePipe,
    FormatFileSizePipe,
    InternalCategoryNamePipe,
    RefLinkPipe,
    SortByPipe,
    SubsidyTypePipe,
    VoucherCountColorPipe,
    VoucherCountPipe
} from '@shared/pipes';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { EpxRefLinkComponent } from './components/ui/misc/epx-ref-link/epx-ref-link.component';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { AppVersionComponent, EnvIndicatorComponent } from '@shared/components/utils';

registerLocaleData(localeDe);

const Components = [
    AppVersionComponent,
    DashboardSkeletonComponent,
    EditorComponent,
    EpxErrorDialogComponent,
    EpxSvgIconComponent,
    EpxPreLoaderComponent,
    EpxAvatarComponent,
    UserProfileMenuComponent,
    EpxMenuComponent,
    EpxModuleLayoutComponent,
    EpxButtonStackComponent,
    EpxGenericDialogComponent,
    EpxTagComponent,
    EpxTagListComponent,
    FileUploadComponent,
    VoucherHistoryComponent,
    ApplicationTableComponent,
    HistoryElementComponent,
    ApplicationStatusTagComponent,
    EpxTagComponent,
    FooterComponent,
    RichTextEditorComponent,
    StretchedModuleContentComponent,
    EpxDataRowComponent,
    EpxDataRowStackComponent,
    EpxLinkButtonComponent,
    EpxExtLinkButtonComponent,
    StretchedModuleContentComponent,
    ContactPersonComponent,
    ContactLinksComponent,
    EpxRefLinkComponent,
    NoDataComponent,
    EpxRangeSelectorComponent,
    EpxLangSelectComponent,
    MessagesComponent,
    EpxConfirmDialogComponent,
    EpxConfirmPopupComponent,
    ContactDataFormComponent,
    EpxRequiredLabelComponent,
    EpxCircleProgressComponent,
    EnvIndicatorComponent
];

const Loaders = [EpxLoaderFluidCircleComponent, EpxLoaderProgressDotsComponent, EpxLoaderCircleComponent];
const EPXDirectives = [EpxEndOfMonthDirective];
const Pipes = [
    ApplicationStatusNamePipe,
    BGColorForStatusPipe,
    ColorForStatusPipe,
    DesignationNamesPipe,
    DescriptionKeyPipe,
    InternalCategoryNamePipe,
    SubsidyTypePipe,
    VoucherCountPipe,
    VoucherCountColorPipe,
    EpxCurrencyPipe,
    EpxCurrencyWithName,
    EpxDateFromMillisPipe,
    EpxStartEndDatePipe,
    EpxMonthDurationPipe,
    EpxMonthNamePipe,
    EpxPluralizationPipe,
    EpxFullNamePipe,
    EpxUserRolePipe,
    EpxAbbreviatePipe,
    EpxTagAbbreviate,
    EpxSvgUrlPipe,
    FormatFileSizePipe,
    RefLinkPipe,
    SortByPipe
];

@NgModule({
    declarations: [...Components, ...Pipes, ...EPXDirectives, ...Loaders],
    imports: [NgOptimizedImage, CommonModule, PrimeNgModule, AngularSvgIconModule, FormsModule, TranslateModule, AvatarModule, DragDropModule, NgxExtendedPdfViewerModule, RoundProgressModule],
    exports: [
        ...Components,
        ...Pipes,
        ...Loaders,
        ...EPXDirectives,
        AngularSvgIconModule,
        AvatarModule,
        DragDropModule,
        FormsModule,
        PrimeNgModule,
        ReactiveFormsModule,
        TranslateModule,
        NgxExtendedPdfViewerModule,
        NgOptimizedImage,
        EpxRefLinkComponent,
        NoDataComponent,
        EpxExtLinkButtonComponent
    ],
    providers: [{ provide: LOCALE_ID, useValue: 'de-DE' }]
})
export class SharedModule {}
