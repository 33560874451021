import { HttpErrorResponse } from '@angular/common/http';

export class BaseApiModel {
    _id = '';
    key?: string;
    rev?: string;
    created?: number;
    updated?: number;
    createdAt?: number;
    updatedAt?: number;
}

export class APIErrorModel extends HttpErrorResponse {
    statusCode = 404;
    displayMessage = '';
}
