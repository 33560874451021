import { Pipe, PipeTransform } from '@angular/core';
import { EmployeeModel } from '@shared/models';

@Pipe({
    name: 'epxFullNamePipe'
})
export class EpxFullNamePipe implements PipeTransform {
    transform(employee: EmployeeModel): string {
        const { firstname, lastname } = employee;
        if (!firstname || !lastname) return '';
        return [firstname, lastname].join(' ');
    }
}
