<form class="stretched" [name]="type + '-contact-form'" #contactForm="ngForm" (ngSubmit)="contactForm.form.valid && submit()">
    <div class="row grid gap-5 align-items-start">
        <div class="col-6 grid">
            <div class="col-12 epx-form-field" [class.invalid]="!cpFullName.pristine && cpFullName.invalid">
                <label for="contact-fullName">
                    <span [innerHTML]="'GENERAL.CONTACT.CONTACT_PERSON' | translate"></span>
                    <span [innerHTML]="'VALIDATION.REQUIRED' | translate"></span>
                </label>
                <div class="epx-input-field">
                    <input #cpFullName="ngModel" [(ngModel)]="data.fullName" id="contact-fullName" name="contact-fullName" type="text" required minlength="3" pInputText />
                    <div class="epx-input-validations">
                        <small *ngIf="!cpFullName.pristine && cpFullName.errors?.['required']">{{ ValidationMessagesMandatory.GENERIC_REQUIRED | translate }}</small>
                    </div>
                </div>
            </div>
            <div class="col-12 epx-form-field" [class.invalid]="!cpEmail.pristine && cpEmail.invalid">
                <label for="contact-e-mail">
                    <span [innerHTML]="'GENERAL.CONTACT.CONTACT_EMAIL' | translate"></span>
                    <span [innerHTML]="'VALIDATION.REQUIRED' | translate"></span>
                </label>
                <div class="epx-input-field">
                    <input #cpEmail="ngModel" [(ngModel)]="data.email" id="contact-e-mail" name="contact-e-mail" type="text" required email pInputText />
                    <div class="epx-input-validations">
                        <small *ngIf="!cpEmail.pristine && cpEmail.errors?.['required']">{{ ValidationMessagesMandatory.GENERIC_REQUIRED | translate }}</small>
                        <small *ngIf="!cpEmail.pristine && cpEmail.errors?.['email']">{{ ValidationMessagesPatterns.EMAIL | translate }}</small>
                    </div>
                </div>
            </div>
            <div class="col-12 epx-form-field" [class.invalid]="!cpPosition.pristine && cpPosition.invalid">
                <label for="contact-position" [innerHTML]="'GENERAL.CONTACT.CONTACT_POSITION' | translate"></label>
                <div class="epx-input-field">
                    <input placeholder="Support, CEO, CTO,  etc." #cpPosition="ngModel" [(ngModel)]="data.position" id="contact-position" name="contact-position" type="text" pInputText />
                </div>
            </div>
        </div>
        <div class="col-6 grid">
            <div class="col-12 epx-form-field" [class.invalid]="!cpCompany.pristine && cpCompany.invalid">
                <label for="contact-company" [innerHTML]="'GENERAL.CONTACT.CONTACT_COMPANY' | translate"></label>
                <div class="epx-input-field">
                    <input #cpCompany="ngModel" [(ngModel)]="data.company" id="contact-company" name="contact-company" type="text" minlength="3" pInputText />
                </div>
            </div>
            <div class="col-12 epx-form-field" [class.invalid]="!cpPhone.pristine && cpPhone.invalid">
                <label for="contact-phone" [innerHTML]="'GENERAL.CONTACT.CONTACT_PHONE' | translate"></label>
                <div class="epx-input-field">
                    <input #cpPhone="ngModel" [(ngModel)]="data.phone" id="contact-phone" name="contact-phone" type="text" [pattern]="RegexConstants.PhoneNumber" pInputText />
                </div>
            </div>
            <div class="col-12 epx-form-field" [class.invalid]="!cpWeb.pristine && cpWeb.invalid">
                <label for="contact-web" [innerHTML]="'GENERAL.CONTACT.CONTACT_WEB' | translate"></label>
                <div class="epx-input-field">
                    <input #cpWeb="ngModel" [(ngModel)]="data.web" id="contact-web" name="contact-web" type="text" [pattern]="RegexConstants.UrlRegex" pInputText />
                    <div class="epx-input-validations">
                        <small *ngIf="!cpWeb.pristine && cpWeb.errors?.['pattern']">{{ ValidationMessagesPatterns.WEB | translate }}</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="row">
            <div class="col-12 flex justify-content-end">
                <button [disabled]="contactForm.form.invalid" type="submit" pButton class="epx-button" label="Speichern"></button>
            </div>
        </div>
    </div>
</form>
