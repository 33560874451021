<p-tag
    [style]="{
        'font-weight': 500,
        background: status | bgColorForStatus,
        color: status | colorForStatus,
        'min-width': '10rem',
        border: status === 'new' ? '1px solid rgba(242, 241, 241, 1)' : '1px solid transparent'
    }">
    <span [style.font-size.px]="10" [innerHTML]="status | applicationStatusName | translate | titlecase"></span>
</p-tag>
