const EmployeeFaqUrl = 'https://emplu.de/faq-mitarbeiter/';
const FeedbackUrl = 'https://wo41dz9db4o.typeform.com/feedback';
const RecommendationUrl = 'https://wo41dz9db4o.typeform.com/recommendation';
const PrivacyPolicy = 'https://emplu.de/datenschutzerklaerung/';
const SupportUrl = 'https://www.emplu.de/support';
const LegalNoticeUrl = 'https://emplu.de/impressum/';
const TermsAndConditions = 'https://emplu.de/nutzungsbedingungen/';
const SupportMail = 'support@emplu.de';
const ApplyForDemo = 'https://outlook.office365.com/owa/calendar/FINBRANDGmbH@finbrand.de/bookings/s/RU4yB5yNJ0-W806r8sZijQ2';

export const appLinks = { EmployeeFaqUrl, FeedbackUrl, RecommendationUrl, PrivacyPolicy, LegalNoticeUrl, TermsAndConditions, SupportMail, ApplyForDemo, SupportUrl };
