<section class="_layout-wrapper">
    <app-dashboard-topbar [sidebarOpen]="viewModel.sidebarOpen"></app-dashboard-topbar>
    <section class="_layout-main">
        <section class="_layout-sidebar" [class.expanded]="viewModel.sidebarOpen">
            <app-dashboard-menu theme="dark" [sidebarOpen]="viewModel.sidebarOpen" (sidebarToggleEvent)="toggleSidebar()"></app-dashboard-menu>
        </section>
        <section class="_layout-content" [class._expanded]="viewModel.sidebarOpen">
            <router-outlet></router-outlet>
        </section>
    </section>
</section>
