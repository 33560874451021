import { BaseApiModel } from '@shared/models/base-api.model';

class FileAttachment extends BaseApiModel {
    filename = '';
    name = '';
    storageName = '';
    downloadURL = '';
    downloadPath = '';
    mimeType: any;
    size?: number;
    progress = 0;
    downLoading = false;
}

export { FileAttachment };
