<section class="avatar-wrapper">
    <ng-container *ngIf="name" [ngTemplateOutlet]="initialsAvatar"></ng-container>
    <ng-container *ngIf="imageSrc" [ngTemplateOutlet]="imageAvatar"></ng-container>
</section>

<ng-template #initialsAvatar>
    <ngx-avatars [bgColor]="bgColor" [fgColor]="fgColor" [size]="size" [name]="name"></ngx-avatars>
</ng-template>

<ng-template #imageAvatar>
    <ngx-avatars [bgColor]="bgColor" [fgColor]="fgColor" [size]="size" [src]="imageSrc"></ngx-avatars>
</ng-template>
