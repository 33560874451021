<div class="progress-wrapper">
    <round-progress
        [current]="currentValue"
        [max]="maxValue"
        [color]="'rgba(50, 50, 50, 1)'"
        [background]="'#eaeaea'"
        [radius]="125"
        [stroke]="10"
        [semicircle]="false"
        [rounded]="true"
        [clockwise]="true"
        [responsive]="true"
        [duration]="800"
        [animation]="'easeInOutQuart'"
        [animationDelay]="0" />
</div>
