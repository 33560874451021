import { BaseApiModel } from '@shared/models/base-api.model';
import { MoneyValueModel } from '@shared/models/money-value.model';
import { EmployeeVoucherModel } from '@modules/benefit/models';
import { ApplicationModel } from '@modules/subsidy/models';

class EmployeeModel extends BaseApiModel {
    userId = '';
    companyId = '';
    firstname = '';
    lastname = '';
    email = '';
    budget: MoneyValueModel = new MoneyValueModel();
    budgetAvailable: MoneyValueModel = new MoneyValueModel();
    fringeBenefitsBudget: MoneyValueModel = new MoneyValueModel();
    reservedBudget: MoneyValueModel = new MoneyValueModel();
    userDeletionDate?: number;
    seniority?: number;
    location?: string;
    deleted?: string;
    staffNumber = '';
    activationDate?: number | null;
    entryDate?: number | null;
    exitDate?: number | null;
    budgetChanged?: number;
    wallet?: EmployeeWalletModel = new EmployeeWalletModel();
}

class EmployeeCreateModel {
    email = '';
    firstname = '';
    lastname = '';
    staffNumber? = '';
    companyId = '';
    budget: MoneyValueModel = new MoneyValueModel();
    activationDate?: number | null;
    entryDate?: number | null;
    exitDate?: number | null;
}

class EmployeeWalletModel {
    subsidies?: ApplicationModel[] = [];
    vouchers?: EmployeeVoucherModel[] = [];
    benefits?: string[] = [];
}

/*class EmployeeUpdateBudgetResponse {
    message?: string;
    displayMessage?: string;
}*/

export { EmployeeWalletModel, EmployeeCreateModel, EmployeeModel };
