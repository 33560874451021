import { Injectable } from '@angular/core';
import { BaseApiService, TAPiServices } from '@services/base-api.service';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { UserModel } from '@shared/models/user.model';
import { AuthService } from '@services/auth.service';
import { NavigationService } from '@services/navigation.service';
import { MessagesService } from '@services/messages.service';

@Injectable({
    providedIn: 'root'
})
export class UserService extends BaseApiService {
    /* eslint-disable @typescript-eslint/member-ordering */
    service: TAPiServices = 'ms-user';
    userAPIUrl = '';
    protected user = new UserModel();
    protected _user = new Subject<UserModel>();
    $user = this._user.asObservable();
    /* eslint-enable @typescript-eslint/member-ordering */

    constructor(
        protected http: HttpClient,
        public authService: AuthService,
        protected navigationService: NavigationService,
        public messages: MessagesService
    ) {
        super();
        this.buildUrl(this.service);
        this.userAPIUrl = `${this.apiUrl}/user`;
        if (this.authService.isLoggedIn()) this.userMe();
    }

    login(email: string, password: string) {
        this.http.post<UserModel>(`${this.userAPIUrl}/login`, { email, password }, this.options(null)).subscribe({
            next: this.handleLogin.bind(this)
        });
    }

    logout() {
        return this.http.delete(`${this.userAPIUrl}/logout`, this.options(this.authService.sessionToken())).subscribe({
            next: this.handleLogout.bind(this)
        });
    }

    userMe() {
        return this.http.get<UserModel>(`${this.userAPIUrl}/me`, this.options(this.authService.sessionToken())).subscribe({
            next: this.updateUser.bind(this),
            error: console.error
        });
    }

    protected handleLogin(user: UserModel) {
        if (user.role !== 'admin:customer') return this.handleRoleForbidden();
        this.authService.storeSession(user?.session);
        this.navigationService.goToDashboard();
        this.userMe();
    }

    protected handleLogout() {
        this.authService.removeSession();
        this.navigationService.goToLogin();
    }

    protected updateUser(user: UserModel) {
        delete user.session;
        this.user = user;
        this._user.next(user);
    }

    protected handleRoleForbidden() {
        this.handleLogout();
        this.messages.showError('ERROR_MESSAGES.WRONG-CREDENTIALS');
    }
}
