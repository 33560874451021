import { Pipe, PipeTransform } from '@angular/core';
import { TUserRole } from '@shared/models/user.model';

@Pipe({
    name: 'epxUserRolePipe'
})
export class EpxUserRolePipe implements PipeTransform {
    transform(role?: TUserRole): any {
        if (role === 'admin:internal') return 'Administrator';
        if (role === 'manager:internal') return 'Manager';
        if (role === 'admin:customer') return 'Administrator';
        if (role === 'manager:customer') return 'Manager';
        if (!role) return 'Keine Rolle Vergeben';
    }
}
