import { Component, Input } from '@angular/core';
import { TApplicationStatus } from '@shared/types';

@Component({
    selector: 'app-application-status-tag',
    templateUrl: './application-status-tag.component.html',
    styleUrls: ['./application-status-tag.component.scss']
})
export class ApplicationStatusTagComponent {
    @Input() status: TApplicationStatus = 'new';
}
