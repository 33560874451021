import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EditorTextChangeEvent } from 'primeng/editor';

interface ITextEditorUpdate {
    rawText: string;
    formattedText: string;
}

@Component({
    selector: 'app-rich-text-editor',
    templateUrl: './rich-text-editor.component.html',
    styleUrls: ['./rich-text-editor.component.scss']
})
export class RichTextEditorComponent implements OnInit {
    @Input() headerLabel = 'Text Editor';
    @Input() rawText = '';
    @Input() formattedText = '';

    @Output() updateTextEmitter: EventEmitter<string> = new EventEmitter<string>();
    @Output() closeDialogEmitter: EventEmitter<undefined> = new EventEmitter<undefined>();

    editText: ITextEditorUpdate = {} as ITextEditorUpdate;

    ngOnInit() {
        this.editText = { formattedText: this.formattedText, rawText: this.rawText };
    }

    updateText(event: EditorTextChangeEvent) {
        this.editText = { rawText: event.textValue, formattedText: event.htmlValue };
    }

    emitTextChange() {
        this.updateTextEmitter.emit(this.editText.rawText);
    }
}
